export default {
    currentDomain: "https://paynexa.co/",
    apiEndPoint: "https://api.paynexa.co/",
    firebaseConfig: {
        apiKey: "AIzaSyAq5V8JyjieOyUARJ9d0yFgFqYZAzJOp8M",
        authDomain: "pingbox-prod.firebaseapp.com",
        databaseURL: "https://pingbox-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "pingbox-prod",
        storageBucket: "pingbox-prod.appspot.com",
        messagingSenderId: "522398746281",
        appId: "1:522398746281:web:64c38a330ad821730b0bb8",
        measurementId: "G-3WE2FSHF75"
    }
}
